import { Component, ViewEncapsulation } from '@angular/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-definition-list',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './definition-list.component.html',
  styleUrls: ['./definition-list.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class DefinitionListComponent {}
